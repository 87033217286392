import { Hero } from "./Hero"
import { Categories } from "./Categories"
import { Banner } from "./Banner"
import { StaggeredListing } from "./StaggeredListing"
import { SummaryListing } from "./SummaryListing"
import { ThreeColumnListing } from "./ThreeColumnListing"
import { Socials } from "./Socials"
import { CategoriesWithImage } from "./CategoriesWithImage"

export const components = {
  hero: Hero,
  categories: Categories,
  banner: Banner,
  staggered_listing: StaggeredListing,
  summary_listing: SummaryListing,
  three_column_listing: ThreeColumnListing,
  socials: Socials,
  categories_with_image: CategoriesWithImage,
}
