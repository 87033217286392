import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { Container } from "./Container"
import { FaLinkedin, FaInstagram, FaTwitter, FaFacebook} from 'react-icons/fa'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import * as s from "./BottomMenu.module.scss"

export const BottomMenu = ({menu, socials}) => {
  const [email, setEmail] = React.useState("");
  const [result, setResult] = React.useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    const Result = await addToMailchimp(email);
    setResult(Result);
  }
  const handleChange = e => {
    setEmail(event.target.value);
  }

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const [state, setState] = React.useState({})

  const handleMessageChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmitMessage = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/en/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  return (
    <>
      <section className={s.newsletter}>
        <Container>
          <div className={s.FlexWrap}>
            <div className={s.CopyWrap}>
              <PrismicRichText field={menu?.newsletter_rt?.richText}/>
              <form onSubmit={handleSubmit}>
                <label htmlFor="NewsletterEmail">You Email:</label>
                <input id="NewsletterEmail" name="NewsletterEmail" type="email" />
                <input type="submit" className={s.btnPrimary} value="Sign Up"/>
              </form>
              {
                result?.result == 'success' ? (
                  <p>{result?.msg}</p>
                ) :
                result?.result == 'error' ? (
                  <p>{result?.msg}</p>
                ) :
                null
              }
            </div>
            <div className={s.ImageWrap}>
              <GatsbyImage
                image={menu?.newsletter_image?.gatsbyImageData}
                alt={menu?.newsletter_image?.alt || ""}
              />
            </div>
          </div>
        </Container>
      </section>
      <footer >
        <Container>
          <div className={s.FlexWrap}>
            <div className={s.FlexCol}>
              <h3 className={s.colHeader}>Website</h3>
              {menu?.website_links?.map((item,index) => (
                <PrismicLink href={item.link?.url} key={`link:${index}`}>
                  {item.link_label}
                </PrismicLink>
              ))}
            </div>
            <div className={s.FlexCol}>
              <h3 className={s.colHeader}>Contact</h3>
              <a href={`mailto:${socials.email}`}>Email: {socials.email}</a>
              <a href={`tel:${socials.phone_number}`}>Phone: {socials.phone_number}</a>
            </div>
            <div className={s.FlexCol}>
              <h3 className={s.colHeader}>Follow</h3>
              <a href={`https://ca.linkedin.com/in/${socials.linked_in}`}><FaLinkedin/>{socials.linked_in}</a>
              <a href={`https://twitter.com/${socials.twitter}`}><FaTwitter/>{socials.twitter}</a>
              <a href={`https://www.instagram.com/${socials.instagram.slice(1)}`}><FaInstagram/>{socials.instagram}</a>
              <a href={`https://www.facebook.com/${socials.facebook}`}><FaFacebook/>{socials.facebook}</a>
            </div>
            <div className={s.FlexCol} id="contact">
              <h3 className={s.colHeader}>Send a Message</h3>
              <form  
                name="contact" 
                method="POST" 
                data-netlify-honeypot="bot-field"
                data-netlify="true" 
                onSubmit={handleSubmitMessage}
                action="/thankYou/">
                <input type="hidden" name="form-name" value="contact" onChange={handleMessageChange}/>
                <p hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" onChange={handleMessageChange} />
                  </label>
                </p>
                <label htmlFor="Name" style={{display:"none"}}>Your Name:</label>
                <input type="text" onChange="" id="Name" name="Name" placeholder="Your Name:" onChange={handleMessageChange} required/>
                <label htmlFor="Email" style={{display:"none"}}>Your Email:</label>
                <input type="email" onChange="" id="Email" placeholder="Your Email:" name="Email" onChange={handleMessageChange} required/>
                <label htmlFor="Message" style={{display:"none"}}>Message:</label>
                <textarea onChange="" rows="5" placeholder="Your Message.." name="Message:" id="Message" onChange={handleMessageChange} required></textarea>
                <input type="submit" className={s.btnPrimary} value="Send Message" onChange={handleMessageChange}/>
              </form>
            </div>
          </div>
          <p className={s.copyRight}>{menu?.copyright}</p>
        </Container>  
      </footer>
    </>
  )
}

export const query = graphql`
  fragment BottomMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      newsletter_rt {
        richText
      }
      newsletter_image {
        gatsbyImageData(width: 570)
        alt 
      }
      website_links {
        link_label
        link {
          url
        }
      }
      copyright
    }
  }
`
