// extracted by mini-css-extract-plugin
export var Body = "SummaryListing-module--Body--81f20";
export var Container = "SummaryListing-module--Container--00453";
export var FlexCol = "SummaryListing-module--FlexCol--7fd40";
export var FlexWrap = "SummaryListing-module--FlexWrap--1219b";
export var H2 = "SummaryListing-module--H2--2be2a";
export var H3 = "SummaryListing-module--H3--2c37a";
export var H4 = "SummaryListing-module--H4--99ac9";
export var H5 = "SummaryListing-module--H5--afe2c";
export var H6 = "SummaryListing-module--H6--5bd50";
export var HeaderWrap = "SummaryListing-module--HeaderWrap--94c6d";
export var LargeBody = "SummaryListing-module--LargeBody--ac4b0";
export var ListingGrid = "SummaryListing-module--ListingGrid--4c844";
export var SummaryListing = "SummaryListing-module--SummaryListing--d3b9b";
export var articleImage = "SummaryListing-module--articleImage--b4b0f";
export var articleMeta = "SummaryListing-module--articleMeta--e8fef";
export var btnPrimary = "SummaryListing-module--btnPrimary--29bc9";
export var readLink = "SummaryListing-module--readLink--a239f";
export var viewAll = "SummaryListing-module--viewAll--b796e";