// extracted by mini-css-extract-plugin
export var Body = "Categories-module--Body--15714";
export var Categories = "Categories-module--Categories--5ef95";
export var CategoryList = "Categories-module--CategoryList--321a1";
export var Container = "Categories-module--Container--06cfb";
export var FlexCol = "Categories-module--FlexCol--f55b7";
export var FlexWrap = "Categories-module--FlexWrap--890a4";
export var H2 = "Categories-module--H2--4dcda";
export var H3 = "Categories-module--H3--6ab59";
export var H4 = "Categories-module--H4--8b937";
export var H5 = "Categories-module--H5--e4a67";
export var H6 = "Categories-module--H6--b92f8";
export var HeaderWrap = "Categories-module--HeaderWrap--bf3c3";
export var LargeBody = "Categories-module--LargeBody--3b505";
export var articleMeta = "Categories-module--articleMeta--c6a9f";
export var btnPrimary = "Categories-module--btnPrimary--372d4";
export var readLink = "Categories-module--readLink--a3f7e";
export var viewAll = "Categories-module--viewAll--1cf7c";