import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const menu = data.prismicMenu || {}
  const socialsData = data.prismicContactInfo || {}
  const socials = socialsData?.data || []
  const articlesData = data.allPrismicArticle || {}
  const articles = articlesData?.nodes || []

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout menu={menu.data} activeDocMeta={activeDoc}>
      <SliceZone slices={homepage.data?.body} components={components} context={{articles: articles, socials: socials}}/>
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
        
          ...HomepageDataBodyBanner
          ...HomepageDataBodyThreeColumnListing
          ...HomepageDataBodyCategories
          ...HomepageDataBodyHero
          ...HomepageDataBodySummaryListing
          ...HomepageDataBodySocials
          ...HomepageDataBodyStaggeredListing

        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
    prismicContactInfo(lang: { eq: $lang }) {
      data {
        twitter
        phone_number
        linked_in
        instagram
        facebook
        email
      }
    }
    allPrismicArticle {
      nodes {
        uid
        data {
          article_date
          article_text {
            richText
          }
          featured
          featured_image {
            gatsbyImageData
            alt
          }
          main_category {
            uid
            url
            document {
              ... on PrismicArticleCategory {
                uid
                url
                data {
                  name
                }
              }
            }
          }
          time
          title {
            richText
            text
          }
        }
      }
    }
  }
`

export default withPrismicPreview(HomepageTemplate)
