// extracted by mini-css-extract-plugin
export var Article = "Article-module--Article--c30d2";
export var Body = "Article-module--Body--13eba";
export var BottomBanner = "Article-module--BottomBanner--36268";
export var Container = "Article-module--Container--56409";
export var FlexCol = "Article-module--FlexCol--5631f";
export var FlexWrap = "Article-module--FlexWrap--a8a74";
export var H2 = "Article-module--H2--61562";
export var H3 = "Article-module--H3--23aba";
export var H4 = "Article-module--H4--1f132";
export var H5 = "Article-module--H5--7a618";
export var H6 = "Article-module--H6--960b1";
export var HeaderWrap = "Article-module--HeaderWrap--3cd24";
export var LargeBody = "Article-module--LargeBody--fa898";
export var Newsletter = "Article-module--Newsletter--52e55";
export var SideBanner = "Article-module--SideBanner--1416b";
export var articleContent = "Article-module--articleContent--ccf76";
export var articleHeader = "Article-module--articleHeader--bbb5f";
export var articleImage = "Article-module--articleImage--05e45";
export var articleMeta = "Article-module--articleMeta--5e967";
export var articleTime = "Article-module--articleTime--d3cac";
export var bottomContainer = "Article-module--bottomContainer--c2f31";
export var breadcrumb = "Article-module--breadcrumb--d14b4";
export var btnPrimary = "Article-module--btnPrimary--07dad";
export var readLink = "Article-module--readLink--543c8";
export var shareBar = "Article-module--shareBar--99e17";
export var stickyBoxBottom = "Article-module--stickyBoxBottom--4ee53";
export var stickyBoxCenter = "Article-module--stickyBoxCenter--78426";
export var stickyColumn = "Article-module--stickyColumn--72f41";
export var topContainer = "Article-module--topContainer--8c5e2";
export var viewAll = "Article-module--viewAll--9e418";