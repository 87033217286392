import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import { FaLinkedin, FaInstagram, FaTwitter, FaFacebook} from 'react-icons/fa'

import * as s from './styles/Socials.module.scss';

export const Socials = ({ slice,context }) => {
  const socials = context.socials

  return (
    <section className="Socials">
      <Container>
        <div className={s.HeaderWrap}>
          <PrismicRichText field={slice.primary.header_rt?.richText}/>
        </div>
        <div className={s.FlexWrap}>
          {slice.items.map((item,index) => (
            <PrismicLink href={item.post_link?.url} key={`post:${index}`}>
              <GatsbyImage
                image={item.post_image?.gatsbyImageData}
                alt={item.post_image?.alt || ""}
              />
            </PrismicLink>
          ))}
        </div>
        <div className={s.HeaderWrap}>
          <PrismicRichText field={slice.primary.subheader_rt?.richText}/>
        </div>
        <div className={s.CenterWrap}>
          <a href={`https://ca.linkedin.com/in/${socials.linked_in}`}><FaLinkedin/>{socials.linked_in}</a>
          <a href={`https://twitter.com/${socials.twitter}`}><FaTwitter/>{socials.twitter}</a>
          <a href={`https://www.instagram.com/${socials.instagram.slice(1)}`}><FaInstagram/>{socials.instagram}</a>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySocials on PrismicHomepageDataBodySocials {
    id
    primary {
      header_rt {
        richText
      }
      subheader_rt {
        richText 
      }
    }
    items {
      post_image {
        gatsbyImageData
        alt 
      }
      post_link {
        url
      }
    }
  }
`
