import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"

import * as s from './styles/Categories.module.scss';

export const Categories = ({ slice }) => {
  return (
    <section className={s.Categories}>
      <Container>
        <div className={s.HeaderWrap}>
          <PrismicRichText field={slice.primary.header_rt?.richText}/>
        </div>
        <div className={s.CategoryList}>
          {slice.items.map((item,index) => (
            <PrismicLink href={item.category_list?.document.url} key={`cat-nav:${index}`}>
              {item.category_list?.document?.data?.name}
            </PrismicLink>
          ))}
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCategories on PrismicHomepageDataBodyCategories {
    id
    primary {
      header_rt {
        richText
      }
    }
    items {
      category_list {
        document {
          ... on PrismicArticleCategory {
            url
            data {
              name
            }
          }
        }
      }
    }
  }
`
