import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { BottomMenu } from './BottomMenu'

export const Layout = ({ children, menu, activeDocMeta }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      prismicContactInfo {
        data {
          email
          facebook
          instagram
          linked_in
          phone_number
          twitter
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{queryData.site.siteMetadata.title}</title>
        <meta
          name="description"
          content={queryData.site.siteMetadata.description}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <TopMenu menu={menu} activeDocMeta={activeDocMeta} />
      <main id="main">{children}</main>
      <BottomMenu menu={menu} socials={queryData.prismicContactInfo?.data}/>
    </>
  )
}
