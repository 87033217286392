import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Container } from './Container'
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { FaBars, FaTimes} from 'react-icons/fa';

import useWindowDimensions from './useWindowDimensions'

import * as s from './TopMenu.module.scss'

export const TopMenu = ({ menu, activeDocMeta }) => {

  const mobileWidth = 1024;
  const {height, width} = useWindowDimensions();
  const isMobile = width && width < mobileWidth? true : false;
  const [expand, setExpand] = React.useState();

  const handleExpand = () => {
    setExpand((expand) => !expand);
  }

  React.useEffect(() => {

  }, [])
  const MenuLinks = (
    <ul className={s.menuLinks}>
      {menu?.website_links?.map((item,index) => (
        <li key={`menu-link:${index}`}>
          <PrismicLink href={item.link?.url}>
            {item.link_label}
          </PrismicLink>
        </li>  
      ))}
      <li key={`menu-link:${menu?.website_links.length + 1}`}>
          <PrismicLink href="#contact">
            Contact
          </PrismicLink>
      </li> 
      <li key={`menu-link:${menu?.website_links.length + 2}`}>
        <a href="tel:416-467-8627" className={s.btnPrimary}>
          <FaPhoneAlt /> 416-467-8627
        </a>
      </li>
    </ul>
  );
  const Mobile = (
    <div className={s.mobile}>
      {expand ? <FaTimes onClick={handleExpand}/> : <FaBars onClick={handleExpand}/>}
      <div className={`${s.MobileMenu} ${expand ? s.MenuExpanded : ""}`}>
        {MenuLinks}
      </div>
    </div>
  );
  return (
    <header className={s.header}>
      <a className={s.skip_button} href="#main">   
        Skip to Content
      </a>
      <nav className={s.menu}>
        <PrismicLink href={"/"} className={s.logoWrap}>
          <GatsbyImage 
            image={menu?.logo?.gatsbyImageData}
            alt={menu?.logo?.alt || "Sandy Casella Realty Logo"}
            className={s.logo}
          />
        </PrismicLink>
        {isMobile && width ? Mobile : width ? MenuLinks : null}
      </nav>
    </header>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      logo {
        gatsbyImageData(
          width: 252
        )
        alt
      }
      website_links {
        link_label
        link {
          url 
        }
      }
    }
  }
`
