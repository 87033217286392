// extracted by mini-css-extract-plugin
export var Body = "StaggeredListing-module--Body--7fb4f";
export var Container = "StaggeredListing-module--Container--6998b";
export var FlexCol = "StaggeredListing-module--FlexCol--42100";
export var FlexWrap = "StaggeredListing-module--FlexWrap--9d4af";
export var H2 = "StaggeredListing-module--H2--df7ed";
export var H3 = "StaggeredListing-module--H3--0c658";
export var H4 = "StaggeredListing-module--H4--fd2cf";
export var H5 = "StaggeredListing-module--H5--9d6f6";
export var H6 = "StaggeredListing-module--H6--6b8a0";
export var HeaderWrap = "StaggeredListing-module--HeaderWrap--e0757";
export var LargeBody = "StaggeredListing-module--LargeBody--77b0f";
export var ListingGrid = "StaggeredListing-module--ListingGrid--870e9";
export var ListingItem = "StaggeredListing-module--ListingItem--a0944";
export var StaggeredListing = "StaggeredListing-module--StaggeredListing--4e0a7";
export var articleImage = "StaggeredListing-module--articleImage--542a8";
export var articleMeta = "StaggeredListing-module--articleMeta--a7c08";
export var btnPrimary = "StaggeredListing-module--btnPrimary--1a68a";
export var readLink = "StaggeredListing-module--readLink--305e9";
export var viewAll = "StaggeredListing-module--viewAll--e3afe";