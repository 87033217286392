// extracted by mini-css-extract-plugin
export var Banner = "Banner-module--Banner--fd3d0";
export var Body = "Banner-module--Body--4e7ec";
export var Container = "Banner-module--Container--4af04";
export var FlexCol = "Banner-module--FlexCol--9be81";
export var FlexWrap = "Banner-module--FlexWrap--669c0";
export var H2 = "Banner-module--H2--934b6";
export var H3 = "Banner-module--H3--3daa4";
export var H4 = "Banner-module--H4--9611f";
export var H5 = "Banner-module--H5--c9e49";
export var H6 = "Banner-module--H6--bef08";
export var HeaderWrap = "Banner-module--HeaderWrap--1622f";
export var ImageWrap = "Banner-module--ImageWrap--63d08";
export var LargeBody = "Banner-module--LargeBody--dd4f3";
export var TextWrap = "Banner-module--TextWrap--79f78";
export var articleMeta = "Banner-module--articleMeta--32c35";
export var btnPrimary = "Banner-module--btnPrimary--1342d";
export var readLink = "Banner-module--readLink--88e1a";
export var viewAll = "Banner-module--viewAll--b2ead";