// extracted by mini-css-extract-plugin
export var Body = "CategoriesWithImage-module--Body--1a07d";
export var CategoriesWithImage = "CategoriesWithImage-module--CategoriesWithImage--8559c";
export var CategoryItem = "CategoriesWithImage-module--CategoryItem--4772c";
export var CategoryLabel = "CategoriesWithImage-module--CategoryLabel--deb21";
export var Container = "CategoriesWithImage-module--Container--578d4";
export var FlexCol = "CategoriesWithImage-module--FlexCol--8bae1";
export var FlexWrap = "CategoriesWithImage-module--FlexWrap--aebbd";
export var H2 = "CategoriesWithImage-module--H2--03899";
export var H3 = "CategoriesWithImage-module--H3--17d57";
export var H4 = "CategoriesWithImage-module--H4--de8bf";
export var H5 = "CategoriesWithImage-module--H5--fd904";
export var H6 = "CategoriesWithImage-module--H6--e56e8";
export var HeaderWrap = "CategoriesWithImage-module--HeaderWrap--51e6b";
export var LargeBody = "CategoriesWithImage-module--LargeBody--b1388";
export var articleMeta = "CategoriesWithImage-module--articleMeta--574d5";
export var btnPrimary = "CategoriesWithImage-module--btnPrimary--0f65a";
export var readLink = "CategoriesWithImage-module--readLink--fc894";
export var viewAll = "CategoriesWithImage-module--viewAll--d28ab";