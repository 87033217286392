import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"

import * as s from './styles/Banner.module.scss';



export const Banner = ({ slice }) => {
  const banner_img = slice.primary.banner?.document?.data?.banner_image || null;
  const banner_txt = slice.primary.banner?.document?.data?.banner_text || null;
  const banner_link = slice.primary.banner?.document?.data?.link?.url || null

  const image = (
    <div className={s.ImageWrap}>
      <GatsbyImage
        image={banner_img?.gatsbyImageData}
        alt={banner_img?.alt || ""}
      />
    </div>
  );
  const text = (
    <div className={s.TextWrap}>
      <PrismicRichText field={banner_txt?.richText}/>
      <PrismicLink className={s.btnPrimary} href={banner_link} >
        Learn More
      </PrismicLink>
    </div>
  )
  return (
    <section className={s.Banner}>
      <Container>
        <div className={s.FlexWrap}>
          {slice.primary.image_side ? (<>{text}{image}</>) : (<>{image}{text}</>)}
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyBanner on PrismicHomepageDataBodyBanner {
    id
    primary {
      image_side
      banner {
        document {
          ... on PrismicActionableBanner {
            data {
              banner_image {
                gatsbyImageData
                alt
              }
              banner_text {
                richText
              }
              link {
                url
              }
            }
          }
        }
      }
    }
  }
`
