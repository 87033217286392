// extracted by mini-css-extract-plugin
export var Body = "BottomMenu-module--Body--be495";
export var Container = "BottomMenu-module--Container--24fa6";
export var CopyWrap = "BottomMenu-module--CopyWrap--34780";
export var FlexCol = "BottomMenu-module--FlexCol--25901";
export var FlexWrap = "BottomMenu-module--FlexWrap--ac353";
export var H2 = "BottomMenu-module--H2--cb2fc";
export var H3 = "BottomMenu-module--H3--51503";
export var H4 = "BottomMenu-module--H4--d2480";
export var H5 = "BottomMenu-module--H5--fd6bb";
export var H6 = "BottomMenu-module--H6--4416f";
export var HeaderWrap = "BottomMenu-module--HeaderWrap--e635a";
export var ImageWrap = "BottomMenu-module--ImageWrap--4853c";
export var LargeBody = "BottomMenu-module--LargeBody--1ce8b";
export var articleMeta = "BottomMenu-module--articleMeta--4b5a2";
export var btnPrimary = "BottomMenu-module--btnPrimary--75afd";
export var copyRight = "BottomMenu-module--copyRight--8938d";
export var newsletter = "BottomMenu-module--newsletter--6d8c2";
export var readLink = "BottomMenu-module--readLink--9e7e9";
export var viewAll = "BottomMenu-module--viewAll--c25fa";