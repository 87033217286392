// extracted by mini-css-extract-plugin
export var Body = "Socials-module--Body--842bc";
export var CenterWrap = "Socials-module--CenterWrap--ddb8a";
export var Container = "Socials-module--Container--37046";
export var FlexCol = "Socials-module--FlexCol--04c92";
export var FlexWrap = "Socials-module--FlexWrap--459f6";
export var H2 = "Socials-module--H2--ada66";
export var H3 = "Socials-module--H3--e7075";
export var H4 = "Socials-module--H4--8f5f3";
export var H5 = "Socials-module--H5--fdddb";
export var H6 = "Socials-module--H6--81077";
export var HeaderWrap = "Socials-module--HeaderWrap--22e40";
export var LargeBody = "Socials-module--LargeBody--d2808";
export var articleMeta = "Socials-module--articleMeta--51118";
export var btnPrimary = "Socials-module--btnPrimary--fbab4";
export var readLink = "Socials-module--readLink--4682a";
export var viewAll = "Socials-module--viewAll--4501f";