// extracted by mini-css-extract-plugin
export var ArticleWrap = "Hero-module--ArticleWrap--124fd";
export var Body = "Hero-module--Body--7ec6f";
export var Container = "Hero-module--Container--f2d7a";
export var CopyWrap = "Hero-module--CopyWrap--44609";
export var FeaturedWrap = "Hero-module--FeaturedWrap--a9c7c";
export var FlexCol = "Hero-module--FlexCol--42f07";
export var FlexWrap = "Hero-module--FlexWrap--b153e";
export var H2 = "Hero-module--H2--eac76";
export var H3 = "Hero-module--H3--d9444";
export var H4 = "Hero-module--H4--e7391";
export var H5 = "Hero-module--H5--33e04";
export var H6 = "Hero-module--H6--8f9a6";
export var HeaderWrap = "Hero-module--HeaderWrap--dfd87";
export var Hero = "Hero-module--Hero--56b75";
export var LargeBody = "Hero-module--LargeBody--ff66a";
export var Title = "Hero-module--Title--413ff";
export var articleImage = "Hero-module--articleImage--a732e";
export var articleMeta = "Hero-module--articleMeta--8912d";
export var btnPrimary = "Hero-module--btnPrimary--79eec";
export var readLink = "Hero-module--readLink--ce86f";
export var viewAll = "Hero-module--viewAll--7d5ad";