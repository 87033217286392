import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"

import * as s from './styles/Hero.module.scss';

export const Hero = ({ slice }) => {

  return (
    <section className={s.Hero}>
      <Container>
        <div className={s.FlexWrap}>
          <div className={s.CopyWrap}>
            <PrismicRichText field={slice.primary.hero_rt?.richText}/>
          </div>
          <div className={s.FeaturedWrap}>
            <div className={s.ColHeader}>
              <PrismicRichText field={slice.primary.listing_title?.richText}/>
            </div>
            <div className={s.FlexCol}>
              {slice.items?.map((item,index) => (
                <a href={`${item.article.document.data.main_category?.url || "All"}/${item.article.uid}`} className={s.ArticleWrap}  key={`feature-article:${index}`}>
                  <div className={s.articleImage}>
                    {item.article.document.data.featured_image && 
                      <GatsbyImage
                        image = {item.article.document.data.featured_image?.gatsbyImageData}
                        alt = {item.article.document.data.featured_image?.alt || ""}
                      />
                    }
                  </div>
                  <div>
                    <p className={s.Title}>{item.article.document.data.title?.text}</p>
                    <div> <span>{item.article.document.data.article_date}</span> &middot; <span>{item.article.document.data.time} Read</span></div> 
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHero on PrismicHomepageDataBodyHero {
    id
    primary {
      hero_rt {
        richText 
      }
      listing_title {
        richText 
      }
    }
    items {
      article {
        url
        uid
        document {
          ... on PrismicArticle {
            id
            data {
              title {
                text
              }
              time
              article_date
              main_category{
                url
              }
              featured_image {
                gatsbyImageData(
                  width: 133
                  height: 133
                )
                alt  
              }
            }
          }
        }
      }
    }
  }
`
